export default {
  titleTemplate: "ZAV | %s",
  description: "Beyond the Limits.",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "http://zavarchitects.com/",
    site_name: "ZAV",
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
};
