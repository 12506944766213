import React from "react";
import {DefaultSeo} from "next-seo";
import App from "next/app";
import * as Sentry from "@sentry/node";

import SEO from "../../seo.config";

import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";
import "../assets/css/main.css";

import Head from "next/head";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function ZavApp({Component, pageProps, err}) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <DefaultSeo {...SEO} />
      <Component {...pageProps} err={err} />
    </>
  );
}

ZavApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);
  return {...appProps};
};

export default ZavApp;
